<template>
  <div>
    <p v-show="errorProfesor" class="text-center">
      <v-icon>mdi-alert-circle</v-icon>
      {{ errorProfesor }}
    </p>

    <v-card
      class="mx-auto"
      max-width="800"
      :loading="loadingRecibo"
      v-if="!errorProfesor"
    >
      <v-card-title>
        <v-img contain max-height="150" src="../../assets/bill.png"> </v-img>
      </v-card-title>

      <br />

      <h2 class="text-center">Recibo #{{ recibo.numero }}</h2>

      <br />

      <v-divider></v-divider>

      <v-card-actions class="mb-4 text-center">
        <v-btn
          color="blue"
          @click="
            $router.push({
              name: 'Contratacion',
              params: { id: recibo.idContratacion },
            })
          "
          rounded
          dark
        >
          <v-icon>mdi-handshake</v-icon>
          Contratacion
        </v-btn>

        <v-btn color="teal" @click="togglePagado()" rounded dark>
          <v-icon>mdi-sync</v-icon>
          Estado
        </v-btn>

        <EditarRecibo :recibo="recibo" @edit="getRecibo()"></EditarRecibo>

        <v-btn color="pink" @click="getPDF()" rounded dark>
          <v-icon>mdi-file-pdf-box</v-icon>
          PDF
        </v-btn>

        <EnviarRecibo :recibo="recibo" @edit="getRecibo()"></EnviarRecibo>

        <v-btn color="red" @click="eliminarRecibo()" rounded dark>
          <v-icon>mdi-delete</v-icon>
          Eliminar
        </v-btn>
      </v-card-actions>

      <v-card-text>
        <v-row>
          <v-col>
            <h3>Fecha:</h3>
            <p>{{ new Date(recibo.fecha).toLocaleDateString() }}</p>

            <h3>Número:</h3>
            <p>{{ recibo.numero }}</p>

            <h3>Forma de pago:</h3>
            <p>{{ recibo.formaPago }}</p>

            <h3>Referencia de pago:</h3>
            <p>{{ recibo.refPago }}</p>

            <v-checkbox
              v-model="recibo.enviado"
              label="Enviado"
              @click="toggleEnviado"
            ></v-checkbox>
          </v-col>

          <v-col>
            <h3>Estado:</h3>
            <p v-if="recibo.pagado">Pagado</p>
            <p v-else>Pendiente</p>

            <h3>Subtotal:</h3>
            <p>{{ recibo.subtotal }} €</p>

            <h3 v-if="recibo.descuento">Descuento:</h3>
            <p v-if="recibo.descuento">{{ recibo.descuento }} €</p>

            <h3>Total:</h3>
            <p>{{ recibo.total }} €</p>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text>
        <h3>Clientes:</h3>

        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr v-for="item in recibo.clientes" :key="item">
                <td>{{ item }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-card-text>
        <h3>Servicios cobrados:</h3>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">
                  Servicios
                </th>
                <th class="text-center">
                  Clientes
                </th>
                <th class="text-center">
                  Duración
                </th>
                <th class="text-center">
                  Precio
                </th>
                <th class="text-center">
                  Total
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="item in recibo.servicios" :key="item.nombre">
                <td>{{ item.nombre }}</td>
                <td class="text-center">{{ recibo.clientes.length }}</td>
                <td class="text-center">
                  {{ item.duracion }} {{ item.cobro }}(s/es)
                </td>
                <td class="text-center">
                  {{ item.precioPorCliente }} €/{{ item.cobro }}
                </td>
                <td class="text-center">{{ item.precioTotal }} €</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-card-text>
        <h3>Observaciones:</h3>
        <v-checkbox
          v-model="recibo.mostrarObservaciones"
          label="Mostrar las observaciones en el recibo"
          @click="toggleMostrarObservaciones"
        ></v-checkbox>
        <p>{{ recibo.observaciones }}</p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';
import EditarRecibo from './EditarRecibo';
import EnviarRecibo from './EnviarRecibo';

export default {
  data() {
    return {
      recibo: {},
      errorProfesor: null,
      loadingRecibo: true,
    };
  },
  methods: {
    async getRecibo() {
      try {
        this.loadingRecibo = true;

        const res = await axios.get(
          process.env.VUE_APP_API_URL + '/recibos?id=' + this.$route.params.id
        );

        this.recibo = res.data;

        this.loadingRecibo = false;
      } catch (error) {
        this.loadingRecibo = false;

        this.errorProfesor = error.response.data;
      }
    },
    async togglePagado() {
      try {
        await axios.post(process.env.VUE_APP_API_URL + '/recibos/editar', {
          id: this.recibo._id,
          idContratacion: this.recibo.idContratacion,
          pagado: !this.recibo.pagado,
        });

        this.recibo.pagado = !this.recibo.pagado;
      } catch (error) {
        alert('Error - No se ha podido cambiar el estado del recibo');
      }
    },
    async toggleEnviado() {
      try {
        await axios.post(process.env.VUE_APP_API_URL + '/recibos/editar', {
          id: this.recibo._id,
          idContratacion: this.recibo.idContratacion,
          enviado: this.recibo.enviado,
        });
      } catch (error) {
        alert('Error - No se ha podido cambiar el envio del recibo');
      }
    },
    async getPDF() {
      location.href = `${process.env.VUE_APP_API_URL}/recibos/pdf?id=${this.recibo._id}`;
    },
    async eliminarRecibo() {
      try {
        await axios.post(process.env.VUE_APP_API_URL + '/recibos/eliminar', {
          id: this.recibo._id,
        });
        this.$router.push({ name: 'Recibos' });
      } catch (error) {
        alert('Error - No se ha podido eliminar el recibo');
      }
    },
    async toggleMostrarObservaciones() {
      try {
        await axios.post(process.env.VUE_APP_API_URL + '/recibos/editar', {
          id: this.recibo._id,
          idContratacion: this.recibo.idContratacion,
          mostrarObservaciones: this.recibo.mostrarObservaciones,
        });
      } catch (error) {
        alert('Error - No se ha podido cambiar las observaciones');
      }
    },
  },
  components: {
    EditarRecibo,
    EnviarRecibo,
  },
  mounted() {
    this.getRecibo();
  },
};
</script>
