<template>
  <v-dialog persistent v-model="toggleDialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="green" class="ml-2" v-bind="attrs" v-on="on" rounded dark>
        <v-icon>mdi-email</v-icon>
        Enviar
      </v-btn>
    </template>

    <v-card :loading="loading">
      <v-card-title>
        Enviar el recibo por correo electrónico
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="Correo electrónico"
          v-model="correo"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn color="blue" text @click="toggleDialog = false">
          Cancelar
        </v-btn>
        <v-btn color="blue" text @click="enviar()">
          Enviar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: 'EditarRecibo',
  props: ['recibo'],
  data() {
    return {
      toggleDialog: false,
      loading: false,
      correo: null,
    };
  },
  methods: {
    async enviar() {
      try {
        this.loading = true;

        await axios.post(process.env.VUE_APP_API_URL + '/recibos/enviar', {
          id: this.recibo._id,
          correo: this.correo
        });

        this.$emit('edit');

        this.loading = false;

        this.toggleDialog = false;
      } catch (error) {
        this.loading = false;

        alert(error.response.data);
      }
    },
  },
};
</script>
