<template>
  <v-dialog persistent v-model="toggleDialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="orange" class="ml-2" v-bind="attrs" v-on="on" rounded dark>
        <v-icon>mdi-pencil</v-icon>
        Editar
      </v-btn>
    </template>

    <v-card :loading="loading">
      <v-card-title>
        Editar recibo
      </v-card-title>
      <v-card-text>
        <v-select
          v-model="recibo.formaPago"
          :items="formasDePago"
          label="Forma de pago"
          persistent-hint
          clearable
        ></v-select>

        <v-text-field
          label="Referencia del pago"
          v-model="recibo.refPago"
        ></v-text-field>

        <v-text-field
          label="Observaciones"
          v-model="recibo.observaciones"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn color="blue" text @click="toggleDialog = false">
          Cancelar
        </v-btn>
        <v-btn color="blue" text @click="editar()">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: 'EditarRecibo',
  props: ['recibo'],
  data() {
    return {
      toggleDialog: false,
      loading: false,
      formasDePago: ['Efectivo', 'Tarjeta', 'Transferencia', 'Bizum'],
    };
  },
  methods: {
    async editar() {
      try {
        this.loading = true;

        await axios.post(process.env.VUE_APP_API_URL + '/recibos/editar', {
          id: this.recibo._id,
          idContratacion: this.recibo.idContratacion,
          formaPago: this.recibo.formaPago,
          refPago: this.recibo.refPago,
          observaciones: this.recibo.observaciones,
        });

        this.$emit('edit');

        this.loading = false;

        this.toggleDialog = false;
      } catch (error) {
        this.loading = false;

        alert(error.response.data);
      }
    },
  },
};
</script>
